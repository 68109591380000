import {CommonModule} from '@angular/common';
import {AfterContentInit, Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList} from '@angular/core';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {makeElementEditable, selectStoryblokComponent} from 'src/app/utils/utils';
import {DatePickerComponent} from '../date-picker/date-picker.component';
import {ControlContent} from '../input-controls/abstract-control/abstract-control.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tab',
  standalone: true,
  imports: [DatePickerComponent],
  template: `<ng-content></ng-content>`,
  styleUrl: './tab-group.component.scss',
})
/**
 * @export
 * @class TabGroupComponent
 */
export class TabComponent {
  @Input() content!: ControlContent;

  /**
   * Creates an instance of TabComponent.
   */
  constructor(private elementRef: ElementRef, private storyblokService: StoryblokService) {
    this.hide();
  }

  /**
   * @public
   */
  public show() {
    this.elementRef.nativeElement.style.display = 'block';
    selectStoryblokComponent(this.storyblokService.currentStoryId, this.content._uid!);
  }

  /**
   * @public
   */
  public hide() {
    this.elementRef.nativeElement.style.display = 'none';
  }
}

@Component({
  selector: 'app-tab-group',
  standalone: true,
  imports: [TabComponent, CommonModule],
  templateUrl: './tab-group.component.html',
  styleUrl: './tab-group.component.scss',
})
/**
 *
 * @export
 * @class TabGroupComponent
 */
export class TabGroupComponent implements AfterContentInit {
  @Output() tabSelected = new EventEmitter<number>();
  @ContentChildren(TabComponent) tabsList!: QueryList<TabComponent>;
  protected labels: string[] = [];
  protected selectedIndex: number = 0;
  protected tabs: TabComponent[] = [];

  /**
   *
   */
  ngAfterContentInit() {
    this.tabs = this.tabsList.toArray();
    this.tabs[0].show();
  }

  /**
   *
   * @protected
   * @param {number} index
   * @param {Event} event
   */
  protected selectTab(index: number, event: Event) {
    this.tabs[this.selectedIndex].hide();
    this.tabs[index].show();
    this.selectedIndex = index;
    this.tabSelected.emit(index);
    event.stopPropagation();
  }
}
