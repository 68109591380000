import {Component, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, Router, Routes} from '@angular/router';
import {RoutePaths} from './app-routing.module';
import {WelcomeComponent} from './screens/welcome/welcome.component';
import {DateReservationService} from './services/date-reservation.service';
import {GooglePlacesService} from './services/google-places.service';
import {StoryblokService} from './services/storyblok.service';
import {urlParamsResolverStoryblok} from './services/url-params.resolver';
import {UrlParamsService} from './services/url-params.service';
import {sleep} from './utils/sleep';
import {makeEditable, selectStoryblokComponent} from './utils/utils';
import {NavigationService} from './services/navigation.service';

interface EditorConfig {
  paths: string[];
  componentSelector: string;
  contentComponent: string;
}

const editorConfigs:EditorConfig[] = [
  {
    paths: [
      ':selectedLanguage/shows/:showId/content',
      'shows/:showId/content',
      RoutePaths.ROOT + '/' + RoutePaths.WELCOME,
    ],
    componentSelector: 'app-welcome',
    contentComponent: 'WelcomeScreen',
  },
  {
    paths: [
      RoutePaths.ROOT + '/' + RoutePaths.REGISTRATION + '/' + RoutePaths.ABOUT_YOU,
    ],
    componentSelector: 'app-about-you',
    contentComponent: 'AboutYouScreen',
  },
  {
    paths: [
      RoutePaths.ROOT + '/' + RoutePaths.REGISTRATION + '/' + RoutePaths.VEHICLE_SELECT,
    ],
    componentSelector: 'app-vehicle-select',
    contentComponent: 'VehicleSelectionScreen',
  },
  {
    paths: [
      RoutePaths.ROOT + '/' + RoutePaths.REGISTRATION + '/' + RoutePaths.DATE_SELECT,
    ],
    componentSelector: 'app-date-select',
    contentComponent: 'DateSelectScreen',
  },
  {
    paths: [
      RoutePaths.ROOT + '/' + RoutePaths.REGISTRATION + '/' + RoutePaths.WAIVER,
    ],
    componentSelector: 'app-waiver',
    contentComponent: 'WaiverScreen',
  },
  {
    paths: [
      RoutePaths.ROOT + '/' + RoutePaths.THANK_YOU,
    ],
    componentSelector: 'app-thank-you',
    contentComponent: 'ThankYouScreen',
  },
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
/**
 *
 * @export
 * @class AppComponent
 */
export class AppComponent implements OnInit {
  public loading = true;

  /**
   * Creates an instance of AppComponent.
   * @param {StoryblokService} storyblokService
   */
  constructor(public storyblokService: StoryblokService,
    private urlParamsService: UrlParamsService,
    private router: Router,
    private navigationService: NavigationService,
    private dateReservationService: DateReservationService,
    private googlePlacesService: GooglePlacesService) {
    this.modiferRouterForEditor();
  }

  /**
   *
   * @return {*}  {Promise<void>}
   */
  async ngOnInit(): Promise<void> {
    this.urlParamsService.updated.subscribe(async ()=>{
      await this.loadContent();
    });
  }

  /**
   * @private
   * @param {string} url
   */
  private async makeRouteEditable(url: string) {
    const getContentUid = (componentName: string) => {
      return this.storyblokService.typedContent[componentName][0]._uid;
    };

    for (const editorConfig of editorConfigs) {
      for (const path of editorConfig.paths) {
        let pathParams = path.replace(':showId', this.urlParamsService.urlParams.showId);
        pathParams = pathParams.replace(':selectedLanguage', this.urlParamsService.urlParams.selectedLanguage);
        if (url.includes(pathParams)) {
          await sleep(1); // wait a frame for the DOM to render
          // const element = document.querySelector(editorConfig.componentSelector);
          const contentUid = getContentUid(editorConfig.contentComponent);
          console.log('contentUid', contentUid);
          selectStoryblokComponent(this.urlParamsService.urlParams.storyId, contentUid);
          makeEditable(editorConfig.componentSelector, this.urlParamsService.urlParams.storyId, contentUid);
        }
      }
    }
  }

  /**
   *
   * @private
   */
  private modiferRouterForEditor() {
    this.removeRouteGuardsAndResolvers(this.router.config);
    const editorRootRoute = {
      component: WelcomeComponent,
      resolve: {
        urlParams: urlParamsResolverStoryblok,
      },
    };
    this.router.config.push(
        {
          path: RoutePaths.ROOT + '/content',
          ...editorRootRoute,
        },
        {
          path: ':selectedLanguage/shows/:showId/content',
          ...editorRootRoute,
        },
    );
  }

  /**
   *
  * @param {Routes} routes
  */
  private removeRouteGuardsAndResolvers(routes: Routes): void {
    const removeFromChildren = (routes: Routes) => {
      for (const route of routes) {
        route.canActivateChild = [];
        route.resolve = {};
        if (route.children) {
          removeFromChildren(route.children);
        }
      }
    };

    removeFromChildren(routes);
  }

  /**
   *
   */
  async loadContent(): Promise<void> {
    const urlParams = this.urlParamsService.urlParams;
    await this.storyblokService.getContentStory(urlParams.selectedLanguage);
    await this.storyblokService.initBridge();
    await this.dateReservationService.loadAvailabilityData('employee-drive'); // (urlParams.showId);
    await this.googlePlacesService.loadAPI();
    this.loading = false;
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
        this.makeRouteEditable(event.url);
      }
    });

    this.makeRouteEditable(this.router.url);
    await sleep(1);
    makeEditable('app-error-notification', urlParams.storyId, this.storyblokService.typedContent.UILabels![0]._uid);
  }

  /**
   * @param {KeyboardEvent} event
   */
  @HostListener('document:keydown', ['$event'])
  public onKeydownHandler(event: KeyboardEvent) {
    if (event?.key === 'ArrowLeft') {
      this.navigationService.previous();
    }
    if (event?.key === 'ArrowRight') {
      this.navigationService.next();
    }
  }
}
