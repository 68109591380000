import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/**
 * Service for redirecting to Single Sign On services
 */
export class SingleSignOnService {
  /**
   *  Google SSO Login
   *
   * @param {string} redirect
   */
  public loginGoogle():void {
    const redirect: string = ' '; // <- this isn't currently being used but can't be empty
    const googleUrl: string = environment.sso.googleUrl;
    // window.location.href = `${googleUrl}?redirect=${redirect}&returnUrl=${environment.url}`;
  }
}
