import {Component, HostBinding} from '@angular/core';
import {NavigationService} from 'src/app/services/navigation.service';
import {routerAnimation} from '../../screens/screens-container/animations';
import {NavigationEnd, Router} from '@angular/router';
import {RoutePaths} from 'src/app/app-routing.module';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
  animations: [routerAnimation()],
})
/**
 *
 * @export
 * @class ProgressbarComponent
 */
export class ProgressbarComponent {
  @HostBinding('class.offscreen-right')
  protected offscreenRight: boolean = false;

  @HostBinding('class.offscreen-left')
  protected offscreenLeft: boolean = false;


  /**
   * Creates an instance of ProgressbarComponent.
   * @param {Router} router
   */
  constructor(
    protected navigationService: NavigationService,
    router: Router,
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.checkOffscreen(val.url);
      }
    });
    this.checkOffscreen(router.url);
  }

  /**
   *
   * @param {string} url
   */
  private checkOffscreen(url: string) {
    this.offscreenRight = url.indexOf(RoutePaths.WELCOME) !== -1 || url.indexOf('content') !== -1;
    this.offscreenLeft = url.indexOf(RoutePaths.THANK_YOU) !== -1;
  }

  /**
   *
   * @protected
   * @return {*}
   */
  protected get currentStep() {
    return Math.max(1, this.navigationService.registrationStep + 1);
  }

  /**
   *
   * @protected
   * @return {*}  {number}
   */
  protected get barWidthPercent(): number {
    return (this.currentStep / this.navigationService.totalRegistrationSteps) * 100;
  };
}

