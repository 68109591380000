import {Component, ElementRef, HostBinding, OnDestroy} from '@angular/core';
import {OverlayNotificationService} from 'src/app/services/overlay-notification.service';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {makeEditable} from 'src/app/utils/utils';

@Component({
  selector: 'app-error-notification',
  standalone: true,
  imports: [],
  templateUrl: './error-notification.component.html',
  styleUrl: './error-notification.component.scss',
})
/**
 * @export
 * @class ErrorNotificationComponent
 */
export class ErrorNotificationComponent {
  /**
   * @readonly
   * @type {boolean}
   */
  @HostBinding('class.visible')
  private visible: boolean = false;
  private TIMEOUT_SECONDS: number = 2;
  protected errorMessage: string = '';

  /**
   * Creates an instance of ErrorNotificationComponent.
   * @param {StoryblokService} storyblokService
   */
  constructor(private storyblokService: StoryblokService,
    private errornotificationService: OverlayNotificationService) {
    // subscribe to error notification service
    this.errornotificationService.showFormErrorEvent.subscribe(() => {
      this.errorMessage = this.storyblokService.typedContent.UILabels![0].ErrorMessage;
      this.show();
    });
    // subscribe to registration error notification service
    this.errornotificationService.showRegistrationErrorEvent.subscribe(() => {
      this.errorMessage = this.storyblokService.typedContent.UILabels![0].RegistrationError!;
      this.show();
    });
  }

  /**
   */
  private show() {
    this.visible = true;
    this.startHideTimeout();
  }

  /**
   * @private
  */
  private hide() {
    this.visible = false;
  }

  /**
   */
  private startHideTimeout() {
    setTimeout(() => {
      this.hide();
    }, this.TIMEOUT_SECONDS * 1000);
  }
}
