/**
 *
 * @private
 * @return {*}  {boolean}
 * @memberof AccountPageComponent
 */
export function isApplePlatform(): boolean {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
      isSafari
  );
}

/**
 *
 * @private
 * @param {string} uri
 * @memberof AccountPageComponent
 */
export function downloadURI(uri: string) {
  const link = document.createElement('a');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

/**
 *
 * @private
 * @return {*}  {boolean}
 * @memberof AccountPageComponent
 */
export function isAndriodPlatform(): boolean {
  return (
    navigator.userAgent.includes('Android') ||
      navigator.platform.includes('Android')
  );
}

/**
 *
 *
 * @class Regex
 */
export class Regex {
  public static zipcode = '^[0-9]{5}(?:-[0-9]{4})?$';
}

/**
 *
 * @param {string} storyId
 * @param {string} componentUuid
 */
export function selectStoryblokComponent(storyId: string | number, componentUuid: string) {
  const btn = document.createElement('button');
  btn.setAttribute('data-blok-c', `{"name": "AboutYouScreen", "space": "", "uid": "${componentUuid}", "id": "${storyId}"}`);
  btn.setAttribute('data-blok-uid', componentUuid);
  btn.classList.add('storyblok__outline');
  document.body.appendChild(btn);
  btn.click();
  document.body.removeChild(btn);
}

/**
 *
 * @export
 * @param {HTMLElement} element
 * @param {string} storyId
 * @param {string} componentUuid
 */
export function makeElementEditable(element: HTMLElement, storyId: string | number, componentUuid: string) {
  element.setAttribute('data-blok-c', `{"space": "", "uid": "${componentUuid}", "id": "${storyId}"}`);
  element.setAttribute('data-blok-uid', componentUuid);
  element.classList.add('storyblok__outline');
}

/**
 * @export
 * @param {string} selector
 * @param {string} storyId
 * @param {string} componentUuid
 */
export function makeEditable(selector: string, storyId: string | number, componentUuid: string) {
  const element = document.querySelector(selector);
  if (element) {
    makeElementEditable(element as HTMLElement, storyId, componentUuid);
    (element as HTMLDivElement).style.pointerEvents = 'unset';
  }
}


/**
 *
 *
 * @param {string} selector
 */
export function disableBtn(selector: string) {
  const element = document.querySelector(selector);
  if (element) {
    // element.setAttribute('disabled', 'true');
    // element.classList.add('disabled');
  }
}

/**
 * Neat utility (written by yours truly) to create a promise and resolve it later
 * Very handy when you're wrapping async code in a promise)
 * @export
 * @return {*}
 */
export function createPromiseWrapper(): {promise:Promise<void>, promiseResolve: (value: void) => void} {
  let promiseResolve: (value: void) => void = () => {};
  const promise = new Promise<void>((resolve, reject) => {
    promiseResolve = resolve;
  });
  return {promise, promiseResolve};
}
