import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {storyblokInit} from '@storyblok/js';
import {environment} from 'src/environments/environment';
import {RichtextResolver, default as StoryblokClient} from 'storyblok-js-client';
import {ButtonStoryblok, ShowContentStoryblok} from '../types/storyblok-component-types';
import {createPromiseWrapper} from '../utils/utils';
import {UrlParamsService} from './url-params.service';

@Injectable({
  providedIn: 'root',
})
/** */
export class StoryblokService {
  public contentLoaded: EventEmitter<ShowContentStoryblok> = new EventEmitter();
  public loading: boolean = true;
  public typedContent!: ShowContentStoryblok;
  public currentStoryId: number = 0;

  private bridgeInited: boolean = false;
  private buttons: ButtonStoryblok[] = [];
  private resolver = new RichtextResolver();
  private client = new StoryblokClient({
    accessToken: environment.storyblokToken,
  });

  /**
   * Creates an instance of StoryblokService.
   * @param {UrlParamsService} urlParamsService
   */
  constructor(private urlParamsService: UrlParamsService,
    private router: Router) {}

  /**
   *
   *
   * @private
   */
  public async initBridge() {
    const {promise, promiseResolve} = createPromiseWrapper();
    if (this.bridgeInited) {
      return;
    }
    this.bridgeInited = true;
    storyblokInit({
      accessToken: environment.storyblokToken,
    });

    const {StoryblokBridge} = window;
    const storyblokBridge = new StoryblokBridge();
    storyblokBridge.on(['enterEditmode'], (event) => {
      promiseResolve();
    });

    storyblokBridge.on(['customEvent', 'published', 'input', 'change', 'unpublished'], (event) => {
      // reload page if save or publish is clicked
      console.log('Storyblok Bridge', event);
      console.log('Storyblok Bridge', event?.action);
      if (event?.story) {
        this.typedContent = event.story.content as ShowContentStoryblok;
        this.buttons = [...this.typedContent.WelcomeScreen[0].Buttons!];
        this.contentLoaded.emit(this.typedContent);
      }
    });

    return promise;
  }


  /**
   * Gets a story
   * @param {string} language - The language
   * @return {Promise<Story>} - Resolves with the story
   */
  public async getContentStory(language: string): Promise<any> {
    this.loading = true;
    // get Story by id (not slug)
    const storyId = this.urlParamsService.urlParams.storyId;
    const {data} = await this.client.getStory(storyId, {
      language,
      version: 'draft',
    });
    this.typedContent = data.story.content as ShowContentStoryblok;
    this.currentStoryId = data.story.id;
    // TODO probably want to automatically recurse through all content
    // and pull about components by type
    this.buttons = [...this.typedContent.WelcomeScreen[0].Buttons!];
    this.loading = false;

    this.contentLoaded.emit(this.typedContent);
  }

  /**
   * render dynamic rich text content
   * @param {any} storyblokHTML
   * @return {any}
   */
  public renderMarkup(storyblokHTML: any) {
    return `${this.resolver.render(storyblokHTML)}`;
  }

  /**
   * Navigate the site to an updated path with the selected language in the path
   * @param {string} lang
   */
  public selectLangage(lang: string): void {
    const selectedLangParamIndex = 1;
    const currentPath = this.router.url.split('/');
    // update selected language component is current path
    currentPath[selectedLangParamIndex] = lang;
    const newPath = currentPath.join('/');
    this.router.navigate([`${newPath}`]);
  }

  /**
   *
   * @param {string} buttonId
   * @return {*}  {(ButtonStoryblok | undefined)}
   */
  public getButton(buttonId: string): ButtonStoryblok | undefined {
    return this.buttons.find((button) => button.Id === buttonId);
  }
}
