import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AboutYouComponent} from './screens/about-you/about-you.component';
import {DateSelectComponent} from './screens/date-select/date-select.component';
import {ThankYouComponent} from './screens/thank-you/thank-you.component';
import {VehicleSelectComponent} from './screens/vehicle-select/vehicle-select.component';
import {WaiverComponent} from './screens/waiver/waiver.component';
import {WelcomeComponent} from './screens/welcome/welcome.component';
import {ssoResultResolver as ssoLoginResultResolver} from './services/single-sign-on.resolver';
import {urlParamsResolver, urlParamsResolverStoryblok} from './services/url-params.resolver';
import {checkAleadyRegistered, checkRegistrationStarted} from './utils/route.guards';

export const RoutePaths = {
  ROOT: ':selectedLanguage/:showId',
  WELCOME: 'welcome',
  REGISTRATION: 'registration',
  ABOUT_YOU: 'about-you',
  VEHICLE_SELECT: 'vehicle-select',
  DATE_SELECT: 'date-select',
  WAIVER: 'waiver',
  THANK_YOU: 'thank-you',

  TMP_TEST_PATH: '/en/nyc-test-2024/welcome',
};

const routes: Routes = [
  {
    path: '',
    redirectTo: RoutePaths.TMP_TEST_PATH,
    pathMatch: 'full',
  },
  {
    path: RoutePaths.ROOT,
    resolve: {
      urlParams: urlParamsResolver,
      ssoResult: ssoLoginResultResolver,
    },
    canActivateChild: [checkAleadyRegistered],
    children: [
      {
        path: '',
        redirectTo: RoutePaths.WELCOME,
        pathMatch: 'full',
      },
      {
        path: RoutePaths.WELCOME,
        component: WelcomeComponent,
        data: {screenPosition: {x: 0}},
      },
      {
        path: RoutePaths.REGISTRATION,
        canActivateChild: [checkRegistrationStarted],
        children: [
          {
            path: RoutePaths.ABOUT_YOU,
            component: AboutYouComponent,
            data: {screenPosition: {x: 1}},
          },
          {
            path: RoutePaths.VEHICLE_SELECT,
            component: VehicleSelectComponent,
            data: {screenPosition: {x: 2}},
          },
          {
            path: RoutePaths.DATE_SELECT,
            component: DateSelectComponent,
            data: {screenPosition: {x: 3}},
          },
          {
            path: RoutePaths.WAIVER,
            component: WaiverComponent,
            data: {screenPosition: {x: 4}},
          },
        ],
      },
      {
        path: RoutePaths.THANK_YOU,
        component: ThankYouComponent,
        data: {screenPosition: {x: 5}},
      },
    ],
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
})
/**
 *
 * @export
 * @class AppRoutingModule
 */
export class AppRoutingModule { }

