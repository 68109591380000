import {AfterViewInit, Component, HostListener} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {OverlayNotificationService} from 'src/app/services/overlay-notification.service';
import {AutoCompleteSelection, GooglePlacesService} from 'src/app/services/google-places.service';
import {RegistrationService, User} from 'src/app/services/registration.service';
import {NavigationService} from 'src/app/services/navigation.service';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {AboutYouScreenStoryblok, InputTextFieldStoryblok} from 'src/app/types/storyblok-component-types';
import {SubscribeToContent} from 'src/app/utils/SubscribeToContent.decorator';
import {makeEditable, Regex} from 'src/app/utils/utils';
import {selectStoryblokComponent} from 'src/app/utils/utils';

// form field ids - basically these are the ids
// of the input fields as defined in storyblok
enum FormFields {
  firstname = 'firstname',
  lastname = 'lastname',
  email = 'email',
  phonenumber = 'phonenumber',
  street = 'street',
  apt = 'apt',
  city = 'city',
  state = 'state',
  zipcode = 'zipcode',
  acceptTerms = 'acceptTerms',
}

@Component({
  selector: 'app-about-you',
  templateUrl: './about-you.component.html',
  styleUrls: ['./about-you.component.scss'],
})
/**
 * @export
 * @class AboutYouComponent
 */
export class AboutYouComponent implements AfterViewInit {
  @SubscribeToContent()
  protected content!: AboutYouScreenStoryblok;
  protected formFields = FormFields;
  protected form: FormGroup;

  /**
   * Creates an instance of AboutYouComponent.
   * @param {FormBuilder} formBuilder
   * @param {NavigationService} NavigationService
   * @param {GooglePlacesService} googlePlacesService
   * @param {StoryblokService} storyblokService
   * @param {RegistrationService} registrationService
   */
  constructor(private formBuilder: FormBuilder,
    private NavigationService: NavigationService,
    private googlePlacesService: GooglePlacesService,
    protected storyblokService: StoryblokService,
    private registrationService: RegistrationService,
    private errorNotification: OverlayNotificationService) {
    // get screen content
    this.content = this.storyblokService.typedContent.AboutYouScreen[0];
    // subscribe to content changes
    this.storyblokService.contentLoaded.subscribe(() =>
      this.content = this.storyblokService.typedContent.AboutYouScreen[0],
    );

    const userData = registrationService.user;
    // build reactive form
    this.form = this.formBuilder.group({
      [FormFields.firstname]: [userData.firstname, [Validators.required]],
      [FormFields.lastname]: [userData.lastname, [Validators.required]],
      [FormFields.email]: [userData.email, [Validators.required, Validators.email]],
      [FormFields.phonenumber]: [userData.phonenumber, [Validators.required]],
      [FormFields.street]: [userData.street, [Validators.required]],
      [FormFields.apt]: [userData.apt, []],
      [FormFields.city]: [userData.city, [Validators.required]],
      [FormFields.state]: [userData.state, [Validators.required]],
      [FormFields.zipcode]: [userData.zipcode, [Validators.required, Validators.pattern(Regex.zipcode)]],
      [FormFields.acceptTerms]: ['', [Validators.requiredTrue]],
    });
    // if user has already logged in via SSO, disable the fields
    if (this.registrationService.ssoComplete) {
      this.form.controls[FormFields.firstname].disable();
      this.form.controls[FormFields.lastname].disable();
      this.form.controls[FormFields.email].disable();
    }
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    this.initGoogleAddressAutoComplete();
  }

  /**
   *
   * @protected
   */
  protected initGoogleAddressAutoComplete(): void {
    const addressConfig = this.getInputFieldContent<InputTextFieldStoryblok>(FormFields.street);
    const addressInputId = addressConfig?.Id;
    const streetInput = document.getElementById(addressInputId!) as HTMLInputElement;

    // init google places autocomplete
    this.googlePlacesService.initGoogleAddressAutoComplete(streetInput);
    this.googlePlacesService.onAutoCompleteSuggestionSelected.subscribe((selection:AutoCompleteSelection) => {
      this.form.patchValue({
        [FormFields.street]: selection.street,
        [FormFields.apt]: selection.apt,
        [FormFields.city]: selection.city,
        [FormFields.state]: selection.state,
        [FormFields.zipcode]: selection.zip,
      });
      this.form.updateValueAndValidity();
    });
  }

  /**
   * @protected
   * @param {string} id
   * @return {*}
   */
  protected getInputFieldContent<T>(id: string): T {
    return this.content.InputFields.find((inputField) => inputField.Id === id) as T;
  }

  /**
   * @protected
   * @param {string} id
   * @return {*}
   */
  protected getFormControl(id: string): FormControl {
    return this.form.controls[id] as FormControl;
  }

  /**
   *
   */
  protected onSubmit(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      this.errorNotification.showFormError();
    } else {
      this.form.enable(); // ensure disabled SSO child fields are included
      this.registrationService.user = this.form.value as User;
      this.NavigationService.next();
    }
  }

  /**
   * TODO move this to registration service
   */
  addTestData() {
    const runningOnLocalhost = window.location.hostname === 'localhost';
    const runningOnStage = window.location.hostname === 'stage.fordautoshows.com';
    const runningOnEditor = window.location.hostname === 'editor.fordautoshows.com';
    const addTestData = runningOnLocalhost || runningOnStage || runningOnEditor;
    if (addTestData) {
      this.form.controls[FormFields.firstname].setValue('Test');
      this.form.controls[FormFields.lastname].setValue('Test');
      this.form.controls[FormFields.email].setValue('Test@Test.com');
      this.form.controls[FormFields.phonenumber].setValue('9175551235');
      this.form.controls[FormFields.street].setValue('Test');
      this.form.controls[FormFields.city].setValue('Test');
      this.form.controls[FormFields.state].setValue('Test');
      this.form.controls[FormFields.zipcode].setValue('10006');
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  @HostListener('document:keydown', ['$event'])
  public onKeydownHandler(event: KeyboardEvent) {
    if (event?.shiftKey && event?.ctrlKey && event?.key === '`') {
      this.addTestData();
    }
  }
}

