import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RoutePaths} from '../app-routing.module';
import {UrlParamsService} from './url-params.service';
@Injectable({
  providedIn: 'root',
})
/**
 * Service that encapsulates the navigation logic for the registration process
 * Starts app at the welcome screen, then goes through the registration screens,
 * and ends at the thank you screen. This keeps all the navigation logic in one
 * place and allows the screen components to focus on their own logic, just calling
 * navigationService.next() / navigationService.previous() to move to next/previous.
 *
 */
export class NavigationService {
  public totalRegistrationSteps!: number;
  public registrationStep: number = 0;
  private registrationRoutes!: string[];

  /**
   * Creates an instance of NavigationService.
   */
  constructor(private router: Router,
    private urlParamsService: UrlParamsService,
    private activatedRoute: ActivatedRoute) {
    // base path of the registration routes
    this.setRegistrationRoutes();
    this.getCurrentRouteIndex();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getCurrentRouteIndex();
      }
    });
  }

  /**
   */
  private setRegistrationRoutes() {
    // find all the child registration routes
    const rootRoutes = this.router.config.find((route) => route.path === RoutePaths.ROOT);
    this.registrationRoutes = rootRoutes?.children!
        .find((route) => route.path === RoutePaths.REGISTRATION)
        ?.children!.map((route) => route.path!)!;
  }

  /**
   * @private
   */
  private getCurrentRouteIndex() {
    // set the total number of registration steps and current step
    const currentRoute = this.router.url.split('/').pop();
    this.registrationStep = this.registrationRoutes.indexOf(currentRoute!);
    this.totalRegistrationSteps = this.registrationRoutes!.length;
  }

  /**
   *
   */
  public next() {
    this.registrationStep = Math.min(this.totalRegistrationSteps, this.registrationStep+1);
    this.gotoRoute();
  }

  /**
   *
   */
  public previous() {
    this.registrationStep = Math.max(-1, this.registrationStep - 1);
    this.gotoRoute();
  }

  /**
   *
   * @private
   */
  private gotoRoute() {
    let path = '';
    if (this.registrationStep === -1) {
      // go to welcome screen
      path = RoutePaths.WELCOME;
    } else if (this.registrationStep === this.totalRegistrationSteps) {
      // go to thank you screen
      path = RoutePaths.THANK_YOU;
    } else {
      // go to registration screen
      const route = this.registrationRoutes[this.registrationStep];
      path = `${RoutePaths.REGISTRATION}/${route}`;
    }
    const urlParams = this.urlParamsService.urlParams;
    const basePath = `/${urlParams.selectedLanguage}/${urlParams.showId}`;
    this.router.navigate([`${basePath}/${path}`], {relativeTo: this.activatedRoute});
  }
}
