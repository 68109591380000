import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ControlContent} from 'src/app/components/input-controls/abstract-control/abstract-control.component';
import {OverlayNotificationService} from 'src/app/services/overlay-notification.service';
import {RegistrationService} from 'src/app/services/registration.service';
import {NavigationService} from 'src/app/services/navigation.service';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {VehicleSelectScreenStoryblok} from 'src/app/types/storyblok-component-types';
import {minimumSelectedValidator} from '../../utils/minimumSelectedValidator';
import {selectStoryblokComponent} from 'src/app/utils/utils';

// form field ids - basically these are the ids
// of the input fields as defined in storyblok
enum FormFields {
  nextAcquisition = 'nextAcquisition',
  vehiclesOfInterest = 'vehiclesOfInterest',
}

@Component({
  selector: 'app-vehicle-select',
  templateUrl: './vehicle-select.component.html',
  styleUrls: ['./vehicle-select.component.scss'],
})
/**
 *
 * @export
 * @class VehicleSelectComponent
 */
export class VehicleSelectComponent {
  protected content!: VehicleSelectScreenStoryblok;
  protected formFields = FormFields;
  protected form: FormGroup;

  /**
   * Creates an instance of VehicleSelectComponent.
   * @param {FormBuilder} formBuilder
   * @param {NavigationService} NavigationService
   * @param {OverlayNotificationService} errorNotificationService
   * @param {StoryblokService} storyblokService
   */
  constructor(private formBuilder: FormBuilder,
    private NavigationService: NavigationService,
    private registrationService: RegistrationService,
    private errorNotificationService: OverlayNotificationService,
    protected storyblokService: StoryblokService) {
    // get screen content
    this.content = this.storyblokService.typedContent.VehicleSelectionScreen![0];
    // subscribe to content changes
    this.storyblokService.contentLoaded.subscribe(() =>
      this.content = this.storyblokService.typedContent.VehicleSelectionScreen![0],
    );

    const buyingIntentionData = registrationService.buyingIntention;
    this.form = this.formBuilder.group({
      [FormFields.nextAcquisition]: [buyingIntentionData.nextAcquisition, [Validators.required]],
      [FormFields.vehiclesOfInterest]: new FormGroup({}, [minimumSelectedValidator(1)]),
    });

    const voiFormGroup = this.form.get(FormFields.vehiclesOfInterest) as FormGroup;
    const vehiclesOfInterest = this.content.Vehicles!;
    for (let i = 0; i < vehiclesOfInterest.length; i++) {
      const vehicleSelected = buyingIntentionData.vehiclesOfInterest.includes(vehiclesOfInterest[i].name!);
      voiFormGroup.addControl(vehiclesOfInterest[i].name!, new FormControl(vehicleSelected));
    }
  }

  /**
   *
   */
  public next() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      this.errorNotificationService.showFormError();
    } else {
      const buyingIntention = this.registrationService.buyingIntention;
      buyingIntention.nextAcquisition = this.form.value.nextAcquisition;
      // convert selected voi list object to array of strings
      buyingIntention.vehiclesOfInterest =
        Object.entries(this.form.value.vehiclesOfInterest)
            .map(([key, value]) => value ? key : null)
            .filter(Boolean) as string[];
      this.NavigationService.next();
    }
  }

  /**
   * Helper for returning typed form control for template
   * @protected
   * @param {string} id
   * @return {*} {(FormControl)}
   */
  protected getFormControl(id: string): FormControl {
    return this.form.controls[id] as FormControl;
  }

  /**
   * Helper for returning typed form group for template
   * @protected
   * @param {string} id
   * @return {*}  {(FormGroup)}
   */
  protected getFormGroup(id: string): FormGroup {
    return this.form.controls[id] as FormGroup;
  }
}
