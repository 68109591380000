import {Directive, ElementRef, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {ButtonStoryblok} from '../types/storyblok-component-types';
import {StoryblokService} from '../services/storyblok.service';
import {Subscription} from 'rxjs';
import {makeEditable, makeElementEditable} from '../utils/utils';

@Directive({
  selector: '[appStoryblokButton]',
  exportAs: 'appStoryblokButton',
  standalone: true,
})
/**
 * Creates a button dom element from a Storyblok button
 * Instead of having to do something like this in your templates:
 * ```
 *  @let ssoLoginBtn = getButton('ssoLogin');
 *  @if (ssoLoginBtn) {
 *     <button>
 *        {{ssoLoginBtn.Label}}
 *     </button>
 *  }
 * ```
 *
 * You can do this:
 * ```
 *  <button appStoryblokButton buttonId="ssoLogin">
 *  </button>
 * ```
 *
 * @export
 * @class StoryblokButtonDirective
 */
export class StoryblokButtonDirective implements OnInit {
  @Input() buttonDefintion: ButtonStoryblok | undefined;

  /**
   * Creates an instance of StoryblokButtonDirective.
   */
  constructor(
    private storyblokService: StoryblokService,
     private el: ElementRef) {
  }

  /**
   *
   * @readonly
   * @type {string}
   */
  @HostBinding('innerHTML')
  public get innerHTML(): string {
    return this.buttonDefintion?.Label || '';
  }

  /**
   */
  ngOnInit() {
    this.initButton();
  }

  /**
   *
   */
  private initButton() {
    if (!this.buttonDefintion) {
      this.removeButton();
      return;
    }
    makeElementEditable(this.el.nativeElement, this.storyblokService.currentStoryId, this.buttonDefintion!._uid);
  }

  /**
   * @private
   */
  private removeButton() {
    this.el.nativeElement.parentElement.removeChild(this.el.nativeElement);
  }
}
