/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import {AfterViewInit, Component, HostBinding, inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {FormVehicleStoryblok} from 'src/app/types/storyblok-component-types';
import {componentProvider} from '../input-controls/abstract-control/abstract-control.component';
import {makeElementEditable, selectStoryblokComponent} from 'src/app/utils/utils';
import {StoryblokService} from 'src/app/services/storyblok.service';

@Component({
  selector: 'app-vehicle-selector',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './vehicle-selector.component.html',
  styleUrl: './vehicle-selector.component.scss',
  providers: componentProvider,
})
/**
 * @export
 * @class VehicleSelectorComponent
 * @extends {AbstractControlComponent}
 */
export class VehicleSelectorComponent implements OnInit, AfterViewInit {
  @Input() error?: string;
  @Input() vehicles?: FormVehicleStoryblok[];
  @Input() formGroup!: FormGroup;
  private selectedVehicles: string[] = [];
  readonly MAX_SELECTION_LENGTH = 3;
  private storyblokService: StoryblokService = inject(StoryblokService);

  /**
   *
   */
  ngOnInit(): void {
    // persist state from model
    this.selectedVehicles = Object.entries(this.formGroup.value)
        .map(([key, value]) => value ? key : null)
        .filter(Boolean) as string[];
  }

  /**
   * Just used for the storyblok editor
   */
  ngAfterViewInit(): void {
    for (const vehicle of this.vehicles!) {
      const element = document.getElementById(vehicle.name!);
      const contentUid = this.vehicles?.find((v) => v.name === vehicle.name!)?._uid;
      makeElementEditable(element!.parentElement!, this.storyblokService.currentStoryId, contentUid!);
      // this.onImageClick = (vehicleName: string) => {};
      // element!.parentElement!.addEventListener('click', () => {
      //   this.onImageClick(vehicle.name!);
      // });
    }
  }

  /**
   * @protected
   * @param {string} id
   * @return {*}
   */
  protected getFormControl(id: string): FormControl {
    return this.formGroup.controls[id] as FormControl;
  }

  /**
   * @readonly
   * @type {boolean}
   */
  @HostBinding('class.error')
  public get showError(): boolean {
    return this.formGroup?.touched && this.formGroup?.invalid;
  }

  /**
   *
   * @protected
   * @param {string} vehicleName
   * @param {Event} event
   */
  protected onSelected(vehicleName: string, event: Event | null = null) {
    event?.stopImmediatePropagation();
    // if the vehicle is already selected
    if (this.selectedVehicles.includes(vehicleName)) {
      // remove the vehicle from the selected vehicles
      this.unselectVehicle(vehicleName);
      return;
    }
    this.selectedVehicles.push(vehicleName);
    // limit the number of selected vehicles
    if (this.selectedVehicles.length > this.MAX_SELECTION_LENGTH) {
      this.removeOldestSeletion();
    }
  }

  /**
   * @private
   * @param {string} vehicleName
   */
  private unselectVehicle(vehicleName: string) {
    this.selectedVehicles = this.selectedVehicles.filter((v) => v !== vehicleName);
  }

  /**
   * @private
   */
  private removeOldestSeletion() {
    // remove the oldest selected vehicle if the selection is too long
    const removed = this.selectedVehicles.shift()!;
    // update the form control to reflect the change
    this.formGroup.controls[removed].setValue(false);
  }

  /**
   *
   * @protected
   * @param {string} vehicleName
   */
  protected onImageClick(vehicleName: string) {
    this.formGroup.controls[vehicleName].setValue(!this.formGroup.controls[vehicleName].value);
    this.onSelected(vehicleName);
  }
}
