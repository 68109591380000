/* eslint-disable valid-jsdoc */
import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {UrlParams, UrlParamsService} from './url-params.service';

/**
 * Resolves the URL parameters for selected language and selected
 * show (which maps to Storyblok id) and sets them in the URL params service.
 */
export const urlParamsResolver: ResolveFn<UrlParams> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      const urlParamsService = inject(UrlParamsService);
      const urlParams = {
        selectedLanguage: route.paramMap.get('selectedLanguage')!,
        showId: route.paramMap.get('showId')!,
        storyId: '',
      };
      console.log('urlParamsResolver', urlParams);
      urlParamsService.set(urlParams);
      return urlParamsService.urlParams;
    };

/**
 * Resolves the URL parameters when embedded in Storyblok iframe
 * as visual editor for selected language and selected
 * show (which maps to Storyblok id) and sets them in the URL params service.
 */
export const urlParamsResolverStoryblok: ResolveFn<UrlParams> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      const searchParams = new URLSearchParams(window.location.search);
      for (const p of searchParams) {
        console.log(p);
      }
      const showId = route.paramMap.get('showId')!;
      const storyId = searchParams.get('_storyblok')!;
      const selectedLanguage = searchParams.get('_storyblok_lang')!;
      const urlParamsService = inject(UrlParamsService);
      const urlParams = {
        selectedLanguage,
        showId,
        storyId,
      };
      urlParamsService.set(urlParams);
      return urlParamsService.urlParams;
    };
