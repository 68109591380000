import {Component, ElementRef, HostBinding, inject, Input, OnInit} from '@angular/core';
import {DefaultValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {StoryblokService} from 'src/app/services/storyblok.service';
import {FfsQuestionStoryblok, InputTextFieldStoryblok} from 'src/app/types/storyblok-component-types';
import {makeElementEditable} from 'src/app/utils/utils';

export interface ControlContent {
  Id: string;
  Label: string;
  Error?: string;
  Autocomplete?: string;
  Answers?: {
    AnswerCode: string;
    AnswerDescription: string;
  }[];
  _uid?: string;
}

// to use this compoonent as a form control we have to provide
// the DefaultValueAccessor which is kind of ugly, ideally
// we should be able to use
//     hostDirectives: [DefaultValueAccessor],
// @see https://github.com/angular/angular/issues/48607
export const componentProvider = [{
  provide: NG_VALUE_ACCESSOR,
  useClass: DefaultValueAccessor,
  multi: true,
}];

@Component({
  selector: 'app-abstract-control',
  standalone: true,
  imports: [],
  template: '',
})
/**
 * AbstractControlComponent
 * Base class for all custom UI form controls
 * Handles basic form control logic for error display
 * and common form inputs
 * @export
 * @class AbstractControlComponent
 */
export class AbstractControlComponent implements OnInit {
    @Input() public content!: ControlContent | InputTextFieldStoryblok | FfsQuestionStoryblok;
    @Input() public formControl!: FormControl;
    @Input() public mask: string = '';
    private _storyblokService: StoryblokService = inject(StoryblokService);

    /**
     * Creates an instance of InputTextFieldComponent.
     * @param {ElementRef} elementRef
     */
    constructor(protected elementRef: ElementRef) {}

    /**
     * @readonly
     * @type {boolean}
     */
    @HostBinding('class.error')
    public get showError(): boolean {
      return this.formControl?.touched && this.formControl?.invalid;
    }

    /**
     * @readonly
     * @type {boolean}
     */
    @HostBinding('class.disabled')
    public get disabled(): boolean {
      return this.formControl?.disabled;
    }

    /**
     */
    ngOnInit() {
      this.hideIfInvalidConfig();
      if (this.content!._uid != null) {
        makeElementEditable(this.elementRef.nativeElement, this._storyblokService.currentStoryId, this.content!._uid!);
      }
    }

    /**
     */
    private hideIfInvalidConfig() {
      if (!this.content || !this.formControl) {
        this.elementRef.nativeElement.style.display = 'none';
        return;
      }
    }
}

